import React from 'react';
import Constitution from './Constitution';

const Resources = () => {
    return (
        <>
            <Constitution />
        </>
    );
}

export default Resources;